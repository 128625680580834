import React, { useState } from "react";

const ManageCategories = ({ categories, setCategories, token }) => {
  const [newCategory, setNewCategory] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [editCategoryDescription, setEditCategoryDescription] = useState("");

  const handleAddCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch("http://localhost:5000/api/admin/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ name: newCategory, description: newDescription }),
      });
      if (res.ok) {
        const addedCategory = await res.json();
        setCategories((prev) => [...prev, addedCategory]);
        setNewCategory("");
        setNewDescription("");
      } else {
        console.error("Failed to add category");
      }
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      const res = await fetch(`http://localhost:5000/api/admin/categories/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (res.ok) {
        setCategories(categories.filter((category) => category._id !== id));
      } else {
        console.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEditCategory = (category) => {
    setEditCategoryId(category._id);
    setEditCategoryName(category.name);
    setEditCategoryDescription(category.description || "");
  };

  const handleUpdateCategory = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`http://localhost:5000/api/admin/categories/${editCategoryId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: editCategoryName, description: editCategoryDescription }),
      });
      if (res.ok) {
        const updatedCategory = await res.json();
        setCategories(
          categories.map((category) =>
            category._id === editCategoryId ? updatedCategory : category
          )
        );
        setEditCategoryId(null);
        setEditCategoryName("");
        setEditCategoryDescription("");
      } else {
        console.error("Failed to update category");
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-6">Manage Categories</h3>

      {/* Category Form */}
      <form
        onSubmit={editCategoryId ? handleUpdateCategory : handleAddCategory}
        className="flex flex-col gap-4 bg-gray-800 p-4 rounded-lg shadow-md"
      >
        <input
          type="text"
          value={editCategoryId ? editCategoryName : newCategory}
          onChange={(e) =>
            editCategoryId
              ? setEditCategoryName(e.target.value)
              : setNewCategory(e.target.value)
          }
          placeholder="Category Name"
          required
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        />
        <textarea
          value={editCategoryId ? editCategoryDescription : newDescription}
          onChange={(e) =>
            editCategoryId
              ? setEditCategoryDescription(e.target.value)
              : setNewDescription(e.target.value)
          }
          placeholder="Category Description"
          rows="3"
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:ring focus:ring-blue-500"
        ></textarea>
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded shadow"
        >
          {editCategoryId ? "Update Category" : "Add Category"}
        </button>
      </form>

      {/* Categories List */}
      <ul className="mt-6 space-y-4">
        {categories.map((category) => (
          <li
            key={category._id}
            className="flex justify-between items-start p-4 bg-gray-800 rounded-lg shadow-md"
          >
            <div>
              <p className="font-medium">{category.name}</p>
              <p className="text-gray-400 text-sm">{category.description || "No description"}</p>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handleEditCategory(category)}
                className="px-3 py-1 bg-yellow-500 hover:bg-yellow-400 text-white rounded shadow"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteCategory(category._id)}
                className="px-3 py-1 bg-red-600 hover:bg-red-500 text-white rounded shadow"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageCategories;
