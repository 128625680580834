import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageFoods = ({token}) => {
    const [foods, setFoods] = useState([]);
    const [diets, setDiets] = useState([]);
    const [foodName, setFoodName] = useState('');
    const [description, setDescription] = useState('');
    const [foodPicture, setFoodPicture] = useState(null);
    const [editingFood, setEditingFood] = useState(null);
    const [dietRatings, setDietRatings] = useState([]); // Stores diet and ratings
    const [nutritionalInfo, setNutritionalInfo] = useState({
        calories: '',
        fat: '',
        fatDailyValue: '',
        saturatedFat: '',
        saturatedFatDailyValue: '',
        cholesterol: '',
        cholesterolDailyValue: '',
        sodium: '',
        sodiumDailyValue: '',
        potassium: '',
        potassiumDailyValue: '',
        carbohydrates: '',
        carbohydratesDailyValue: '',
        dietaryFiber: '',
        dietaryFiberDailyValue: '',
        sugar: '',
        protein: '',
        proteinDailyValue: '',
        vitamins: {},
    });

    
  useEffect(() => {
      fetchFoods();
      fetchDiets();
  console.log(diets);

  }, []);
    const fetchFoods = async () => {
        try {
            const response = await axios.get('https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods');
            setFoods(response.data);
            console.log(foods);
        } catch (error) {
            console.error('Error fetching foods:', error);
            setFoods([]);
        }
    };

    const fetchDiets = async () => {
        try {
            const response = await axios.get('https://supplement-app-d151447d38d4.herokuapp.com/api/admin/diets');
            setDiets(response.data);
        } catch (error) {
            console.error('Error fetching diets:', error);
        }
    };
   
    const addDietRating = () => {
        setDietRatings([...dietRatings, { diet: '', rating: '' }]);
    };

    const updateDietRating = (index, key, value) => {
        const updatedDietRatings = [...dietRatings];
        updatedDietRatings[index][key] = value;
        setDietRatings(updatedDietRatings);
    };
    const [vitaminFields, setVitaminFields] = useState([]);

    // Add a new vitamin field
    const addVitaminField = () => {
        setVitaminFields([...vitaminFields, { name: '', value: '' }]);
    };

    // Update a specific vitamin field
    const handleVitaminChange = (index, key, value) => {
        const updatedFields = [...vitaminFields];
        updatedFields[index][key] = value;
        setVitaminFields(updatedFields);

        // Update nutritionalInfo.vitamins
        setNutritionalInfo((prev) => ({
            ...prev,
            vitamins: {
                ...prev.vitamins,
                [updatedFields[index].name]: updatedFields[index].value,
            },
        }));
    };

    const handleEditFood = async (foodId) => {
      try {
          const response = await axios.get(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods/${foodId}`);
          const food = response.data;
  
          setFoodName(food.name);
          setDescription(food.description);
          setFoodPicture(null); // User needs to re-upload the picture
          setNutritionalInfo(food.nutritionalInfo || {});
          setVitaminFields(food.vitamins || []);
        
          //setDietRatings(formattedDietRatings);
          //setDietRatings(formattedDietRatings);
          setDietRatings(food.dietRatings || []);
          setEditingFood(food); // Marks this food for editing
      } catch (error) {
          console.error('Error fetching food for editing:', error.response?.data?.message || error.message);
      }
  };
  
  
const handleUpdateFood = async () => {
  if (!editingFood) return; // Ensure there's a food being edited

  try {
      const formData = new FormData();
      formData.append('name', foodName);
      formData.append('description', description);
      if (foodPicture) {
          formData.append('picture', foodPicture);
      }
      formData.append('nutritionalInfo', JSON.stringify(nutritionalInfo));
      //formData.append('vitamins', JSON.stringify(vitaminFields));
      formData.append("diets", JSON.stringify(dietRatings));
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      console.log(formData);

      const response = await axios.put(
          `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods/${editingFood._id}`,
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${token}`,
              },
          }
      );

      console.log('Food updated successfully:', response.data);
      fetchFoods(); // Refresh the food list
      clearForm(); // Clear the form after success
  } catch (error) {
      console.error('Error updating food:', error.response?.data?.message || error.message);
  }
};


    const handleAddFood = async () => {
        const formData = new FormData();
        const transformedNutritionalInfo = {
          calories: parseInt(nutritionalInfo.calories, 10),
          fat: {
              total: parseInt(nutritionalInfo.fat, 10),
              dailyValue: parseInt(nutritionalInfo.fatDailyValue, 10),
              saturated: parseInt(nutritionalInfo.saturatedFat, 10),
              saturatedDailyValue: parseInt(nutritionalInfo.saturatedFatDailyValue, 10),
          },
          cholesterol: {
              amount: parseInt(nutritionalInfo.cholesterol, 10),
              dailyValue: parseInt(nutritionalInfo.cholesterolDailyValue, 10),
          },
          sodium: {
              amount: parseInt(nutritionalInfo.sodium, 10),
              dailyValue: parseInt(nutritionalInfo.sodiumDailyValue, 10),
          },
          potassium: {
              amount: parseInt(nutritionalInfo.potassium, 10),
              dailyValue: parseInt(nutritionalInfo.potassiumDailyValue, 10),
          },
          carbohydrates: {
              total: parseInt(nutritionalInfo.carbohydrates, 10),
              dailyValue: parseInt(nutritionalInfo.carbohydratesDailyValue, 10),
              dietaryFiber: {
                  amount: parseInt(nutritionalInfo.dietaryFiber, 10),
                  dailyValue: parseInt(nutritionalInfo.dietaryFiberDailyValue, 10),
              },
              sugar: parseInt(nutritionalInfo.sugar, 10),
          },
          protein: {
              amount: parseInt(nutritionalInfo.protein, 10),
              dailyValue: parseInt(nutritionalInfo.proteinDailyValue, 10),
          },
          vitamins: {
              vitaminC: parseInt(nutritionalInfo.vitamins['Vitamin C'] || 0, 10),
              calcium: parseInt(nutritionalInfo.vitamins['Calcium'] || 0, 10),
              iron: parseInt(nutritionalInfo.vitamins['Iron'] || 0, 10),
              vitaminD: parseInt(nutritionalInfo.vitamins['Vitamin D'] || 0, 10),
              vitaminB6: parseInt(nutritionalInfo.vitamins['Vitamin B6'] || 0, 10),
              cobalamin: parseInt(nutritionalInfo.vitamins['Cobalamin'] || 0, 10),
              magnesium: parseInt(nutritionalInfo.vitamins['Magnesium'] || 0, 10),
          },
        }
        formData.append('name', foodName);
        formData.append('description', description);
        if (foodPicture) formData.append('picture', foodPicture);
        formData.append('nutritionalInfo', JSON.stringify(transformedNutritionalInfo));
        formData.append('dietRatings', JSON.stringify(dietRatings));

        try {
            const response = await axios.post(
                'https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods',
                formData,
                { headers: { 'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${token}`,
                 } }
            );
            console.log('Food added successfully:', response.data);
            fetchFoods();
        } catch (error) {
            console.error('Error adding food:', error);
        }
    };
    const handleDeleteFood = async (foodId) => {
        try {
            await axios.delete(`https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods/${foodId}`);
            console.log('Food deleted successfully');
            fetchFoods();
        } catch (error) {
            console.error('Error deleting food:', error);
        }
    };
    useEffect(() => {
      console.log('Foods updated:', foods);
  }, [foods]);
  const clearForm = () => {
    setFoodName('');
    setDescription('');
    setFoodPicture(null);
    setNutritionalInfo({});
    setVitaminFields([]);
    setDietRatings([]);
    setEditingFood(null);
};
        return (
        <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6 text-center text-gray-700">Manage Foods</h1>

            <div className="grid gap-4 sm:grid-cols-2">
                <div className="flex flex-col">
                    <label className="text-sm font-semibold text-gray-600">Food Name</label>
                    <input
                        type="text"
                        className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                        value={foodName}
                        onChange={(e) => setFoodName(e.target.value)}
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-sm font-semibold text-gray-600">Description</label>
                    <textarea
                        className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>

                <div className="flex flex-col">
                    <label className="text-sm font-semibold text-gray-600">Picture</label>
                    <input
                        type="file"
                        className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                        onChange={(e) => setFoodPicture(e.target.files[0])}
                    />
                </div>
            </div>
            <h3 className="text-xl font-semibold mt-6 text-gray-700">Nutritional Information</h3>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {[
            { label: 'Calories', key: 'calories' },
            { label: 'Fat', key: 'fat' },
            { label: 'Fat Daily Value', key: 'fatDailyValue' },
            { label: 'Saturated Fat', key: 'saturatedFat' },
            { label: 'Saturated Fat Daily Value', key: 'saturatedFatDailyValue' },
            { label: 'Cholesterol', key: 'cholesterol' },
            { label: 'Cholesterol Daily Value', key: 'cholesterolDailyValue' },
            { label: 'Sodium', key: 'sodium' },
            { label: 'Sodium Daily Value', key: 'sodiumDailyValue' },
            { label: 'Potassium', key: 'potassium' },
            { label: 'Potassium Daily Value', key: 'potassiumDailyValue' },
            { label: 'Carbohydrates', key: 'carbohydrates' },
            { label: 'Carbohydrates Daily Value', key: 'carbohydratesDailyValue' },
            { label: 'Dietary Fiber', key: 'dietaryFiber' },
            { label: 'Dietary Fiber Daily Value', key: 'dietaryFiberDailyValue' },
            { label: 'Sugar', key: 'sugar' },
            { label: 'Protein', key: 'protein' },
            { label: 'Protein Daily Value', key: 'proteinDailyValue' },
          ].map(({ label, key }) => (
            <div key={key} className="flex flex-col">
              <label className="text-sm font-semibold text-gray-600">{label}</label>
              <input
                type="number"
                className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                value={nutritionalInfo[key] || ''}
                onChange={(e) =>
                  setNutritionalInfo({ ...nutritionalInfo, [key]: e.target.value })
                }
              />
            </div>
          ))}
        </div>
        <h3 className="text-xl font-semibold mt-6 text-gray-700">Vitamins</h3>
        <div className="space-y-4">
          {vitaminFields.map((field, index) => (
            <div key={index} className="grid gap-4 sm:grid-cols-2">
              <input
                type="text"
                placeholder="Vitamin Name (e.g., Vitamin C)"
                className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                value={field.name}
                onChange={(e) => handleVitaminChange(index, 'name', e.target.value)}
              />
              <input
                type="number"
                placeholder="Value (e.g., 30)"
                className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                value={field.value}
                onChange={(e) => handleVitaminChange(index, 'value', e.target.value)}
              />
            </div>
          ))}
          <button
            type="button"
            className="text-blue-500 hover:underline font-semibold"
            onClick={addVitaminField}
          >
            + Add Vitamin
          </button>
        </div>
            <h3 className="text-xl font-semibold mt-6 text-gray-700">Diet and Ratings</h3>
            <div className="space-y-4">
                {dietRatings.map((dietRating, index) => (
                    <div key={index} className="grid gap-4 sm:grid-cols-2">
                        <select
                            className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                            value={dietRating.diet}
                            onChange={(e) => updateDietRating(index, 'diet', e.target.value)}
                        >
                            <option value="">Select Diet</option>
                            {diets.map((diet) => (
                                <option key={diet._id} value={diet._id}>{diet.name}</option>
                            ))}
                        </select>
                        <input
                            type="number"
                            placeholder="Rating"
                            className="border border-gray-300 rounded p-2 mt-1 focus:outline-none focus:ring focus:ring-blue-300"
                            value={dietRating.rating}
                            onChange={(e) => updateDietRating(index, 'rating', e.target.value)}
                        />
                    </div>
                ))}
                <button
                    type="button"
                    className="text-blue-500 hover:underline font-semibold"
                    onClick={addDietRating}
                >
                    + Add Diet and Rating
                </button>
            </div>

            <div className="mt-6">
    {editingFood ? (
        <button
            type="button"
            className="bg-green-500 text-white py-2 px-4 rounded shadow hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300"
            onClick={handleUpdateFood}
        >
            Update Food
        </button>
    ) : (
        <button
            type="button"
            className="bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
            onClick={handleAddFood}
        >
            Add Food
        </button>
    )}
</div>
      

            <h2 className="text-xl font-bold mt-8 mb-4 text-gray-700">Existing Foods</h2>
            <ul className="divide-y divide-gray-300">
            <ul>
    {Array.isArray(foods) && foods.length > 0 ? (
        foods.map((food) => (
            <li key={food._id} className="py-4 flex justify-between items-center">
                <div>
                    <h3 className="font-semibold text-gray-700">{food.name}</h3>
                    <p className="text-gray-500">{food.description}</p>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={() => handleEditFood(food._id)}
                        className="text-yellow-500 hover:underline"
                    >
                        Edit
                    </button>
                    {editingFood && (
    <button
        type="button"
        className="ml-4 text-gray-500 hover:underline"
        onClick={clearForm}
    >
        Cancel
    </button>
)}

                    <button
                        onClick={() => handleDeleteFood(food._id)}
                        className="text-red-500 hover:underline"
                    >
                        Delete
                    </button>
                </div>
            </li>
        ))
    ) : (
        <p className="text-gray-500">No foods available</p>
    )}
</ul>

            </ul>
        </div>
    );
};

export default ManageFoods;
