import React, { useEffect, useState } from "react";
import ManageProducts from "../components/ManageProducts";
import ManagePaymentMethods from "../components/ManagePaymentMethods";
import ManageShippingMethods from "../components/ManageShippingMethods";
import ManageOrders from "../components/ManageOrders";
import ManageCoupons from "../components/ManageCoupons";
import ManageCategories from "../components/ManageCategories";
import ManageReviews from "../components/ManageReviews";
import ManageDiet from "../components/ManageDiet";
import ManageFoodCategory from "../components/ManageFoodCategory";
import ManageFood from "../components/ManageFood";
function AdminDashboard() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [orders, setOrders] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [diets, setDiets] = useState([]);
  const [foodCategories, setFoodCategories] = useState([]);
  const [foods, setFoods] = useState([]);
  const [activeComponent, setActiveComponent] = useState("ManageProducts");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async (url, setState) => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const res = await fetch(url, { headers });
        if (res.ok) {
          const data = await res.json();
          setState(data);
        } else {
          console.error(`Error fetching ${url}:`, await res.text());
        }
      } catch (error) {
        console.error(`Network error fetching ${url}:`, error);
      }
    };

    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/products", setProducts);
    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/categories", setCategories);
    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/payment_methods", setPaymentMethods);
    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/shipping_methods", setShippingMethods);
    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/orders", setOrders);
    fetchData("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/coupons", setCoupons);
    fetchData("http://localhost:5000/api/admin/foods", setFoods);
    fetchData("http://localhost:5000/api/admin/diets", setDiets);
    fetchData("http://localhost:5000/api/admin/food_categories", setFoodCategories);
  }, [token]);

  const dashboardComponents = {
    ManageProducts: {
      component: ManageProducts,
      props: { products, setProducts, categories, token },
    },
    ManagePaymentMethods: {
      component: ManagePaymentMethods,
      props: { paymentMethods, setPaymentMethods, token },
    },
    ManageShippingMethods: {
      component: ManageShippingMethods,
      props: { shippingMethods, setShippingMethods, token },
    },
    ManageOrders: {
      component: ManageOrders,
      props: { orders, setOrders, token },
    },
    ManageCoupons: {
      component: ManageCoupons,
      props: { coupons, setCoupons, token },
    },
    ManageCategories: {
      component: ManageCategories,
      props: { categories, setCategories, token },
    },
    ManageReviews: {
      component: ManageReviews,
      props: { token },
    },
    ManageDiet: {
      component: ManageDiet,
      props: { diets, setDiets, token },
    },
    ManageFoodCategory: {
      component: ManageFoodCategory,
      props: { foodCategories, setFoodCategories,diets, token },
    },
    ManageFood: {
      component: ManageFood,
      props: { foods, setFoods, token},
    },
  };

  const ActiveComponent = dashboardComponents[activeComponent]?.component;

  return (
    <div className="min-h-screen bg-gray-900 text-gray-200 flex flex-col">
      {/* Header */}
      <header className="bg-black py-4 px-6 shadow-md">
        <h1 className="text-xl font-bold text-white">Admin Dashboard</h1>
      </header>

      <div className="flex flex-1">
        {/* Sidebar */}
        <aside className="w-64 bg-gray-800 p-4 space-y-4">
          <nav className="space-y-2">
            {Object.keys(dashboardComponents).map((key) => (
              <button
                key={key}
                onClick={() => setActiveComponent(key)}
                className={`block w-full text-left px-4 py-2 rounded-md ${
                  activeComponent === key
                    ? "bg-blue-600 text-white"
                    : "hover:bg-gray-700"
                }`}
              >
                {key.replace("Manage", "")}
              </button>
            ))}
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 bg-gray-800 p-6 rounded-tl-lg shadow-lg">
          {ActiveComponent ? (
            <ActiveComponent {...dashboardComponents[activeComponent].props} />
          ) : (
            <p>Select a section from the sidebar</p>
          )}
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;
